import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

import { getUtms } from "../helpers/utm"

const withLocation = (ComponentToWrap: React.ElementType) =>
  class extends React.Component {
    state = {
      utms: "",
    }

    componentDidMount = async () => {
      if (typeof window !== undefined) {
        let country = ""

        const pathCountry = window.location.pathname.replace(/\//g, "")

        if (["chile", "peru", "uruguay"].includes(pathCountry)) {
          country =
            pathCountry.charAt(0).toUpperCase() + pathCountry.substring(1)
        }

        const utms = getUtms(location, document, country)
        this.setState({ utms }, () => {
          window.history.replaceState(null, "", location.pathname + utms)
        })
      }
    }

    render = () => {
      const props = this.props
      const { utms } = this.state

      return (
        <Location>
          {({ location, navigate }) => {
            return (
              <ComponentToWrap
                {...props}
                location={location}
                navigate={navigate}
                search={
                  location.search ? queryString.parse(location.search) : {}
                }
                searchString={utms}
              />
            )
          }}
        </Location>
      )
    }
  }

export default withLocation
