import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "@emotion/styled"

import BackgroundImage from "gatsby-background-image"

import LogoCapitaria from "../images/logo-capitaria.svg"
import TwitterIcon from "../images/twitter-icon.svg"
import FacebookIcon from "../images/facebook-icon.svg"
import InstagramIcon from "../images/instagram-icon.svg"
import LinkedinIcon from "../images/linkedin-icon.svg"
import YoutubeIcon from "../images/youtube-icon.svg"

const Disclaimer = styled.p`
  max-width: 1100px;
  padding: 0 1rem;
  margin: 3rem auto;
  text-align: center;
  font-size: 0.625em;
  color: #f5f5f5;
  @media (max-width: 420px) {
    text-align: justify;
    margin: 0 1rem 3rem;
  }
`
const SocialNetworkWrapper = styled.div`
  text-align: center;
  height: 380px;
  display: flex;
  align-items: flex-end;
`

const SocialNetwork = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  @media (max-width: 420px) {
    margin: 0 1rem;
  }
`

const IconList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
`

const LinkIcon = styled.a`
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
`

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "image-people.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <footer>
          <BackgroundImage
            Tag="section"
            fluid={imageData}
            backgroundColor={`#000000`}
          >
            <SocialNetworkWrapper>
              <SocialNetwork>
                <LogoCapitaria style={{ margin: "0 auto" }} />
                <p>Síguenos y entérate de nuestras novedades</p>
                <IconList>
                  <li style={{ padding: "0 1rem" }}>
                    <LinkIcon
                      href="https://twitter.com/somoscapitaria"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </LinkIcon>
                  </li>

                  <li style={{ padding: "0 1rem" }}>
                    <LinkIcon
                      href="https://www.facebook.com/Capitaria/"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </LinkIcon>
                  </li>

                  <li style={{ padding: "0 1rem" }}>
                    <LinkIcon
                      href="https://www.instagram.com/somoscapitaria/"
                      target="_blank"
                    >
                      <InstagramIcon />
                    </LinkIcon>
                  </li>

                  <li style={{ padding: "0 1rem" }}>
                    <LinkIcon
                      href="https://www.linkedin.com/company/capitaria"
                      target="_blank"
                    >
                      <LinkedinIcon />
                    </LinkIcon>
                  </li>

                  <li style={{ padding: "0 1rem" }}>
                    <LinkIcon
                      href="https://www.youtube.com/c/Capitaria"
                      target="_blank"
                    >
                      <YoutubeIcon />
                    </LinkIcon>
                  </li>
                </IconList>
              </SocialNetwork>
            </SocialNetworkWrapper>
          </BackgroundImage>

          <Disclaimer>
            DECLARACIÓN DE RIESGO En los mercados financieros se generan rápidos
            movimientos que pueden conllevar a un elevado riesgo de pérdidas.
            Por esta razón, participar activamente en estos mercados con
            apalancamiento financiero, solo es aconsejable para aquellos
            participantes que puedan asumir dicho riesgo. Cualquier análisis o
            consejo sobre el mercado comunicado a través de www.capitaria.com o
            de algún correo electrónico con este remitente, debe ser considerado
            como una opinión, y nunca como una garantía. En ningún caso y bajo
            ningún concepto, Capitaria se hace responsable de las posibles
            pérdidas incurridas por nuestros clientes. Todo cliente potencial de
            Capitaria debe tener en cuenta que su capital puede verse tanto
            incrementado como disminuido, no debiendo participar en este mercado
            sin antes considerar si esta manera de operar es la adecuada para
            él, en función de sus condiciones económicas. Los clientes
            potenciales deben también tener en cuenta que no se pueden
            garantizar los resultados de las operaciones, y que los resultados
            obtenidos en el pasado no garantizan resultados futuros. El trading
            implica un alto riesgo y un cliente puede perder una cantidad
            sustancial de dinero, no importando qué método utilice. Capitaria
            ofrece instrumentos transados a través de CFD's, que no están
            regulados por alguna entidad local.*Argentina: depósitos y retiros
            de dinero sólo con cuenta bancaria en el extranjero* Atte. Capitaria
            S.A.
          </Disclaimer>
        </footer>
      )
    }}
  />
)

export default Footer
