import React from "react"
import styled from "@emotion/styled"

import Logo from "../images/logo-hero.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VerticalLine = styled.span`
  color: green;
`

const Instruments = styled.p`
  text-align: center;
`

const Hero = () => (
  <Container>
    <Logo style={{ marginBottom: "0.5rem" }} />

    <Instruments>
      ACCIONES <VerticalLine>|</VerticalLine> COMMODITIES{" "}
      <VerticalLine>|</VerticalLine> DIVISAS
    </Instruments>
  </Container>
)

export default Hero
