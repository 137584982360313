import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import Footer from "./footer"
import "./layout.css"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  background: linear-gradient(180deg, #0c0d2e, #000000 90%);
`

const Container = styled.div`
  padding: 0 calc((100vw - 1440px - 0.5rem) / 2) 0.5rem;
`
const Layout = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Container>
          <main>{children}</main>
        </Container>
      </Wrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
