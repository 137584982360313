type WindowDocument = Document | null

const UTM_CAMPAIGN = "Rise"
const QUERY_STRING_BASE = `?utm_medium=directo&utm_campaign=${UTM_CAMPAIGN}`
const DOMAINS = [
  "google",
  "bing",
  "yahoo",
  "baidu",
  "ask",
  "facebook",
  "instagram",
  "capitaria",
  "fiebredeloro",
]

export const fetchIp = async () => {
  try {
    const response = await fetch(
      "https://api.ipify.org?format=json&callback=?",
      {
        credentials: "omit",
      }
    )
    const json = await response.json()

    return json.ip
  } catch (err) {
    console.log(err)
  } finally {
    return ""
  }
}

export const fetchCountry = async (ip: string) => {
  try {
    const response = await fetch(`https://ip2c.org/${ip}`, {
      credentials: "omit",
    })
    const text = await response.text()
    const countryNemo = text.split(";")[1]

    if (countryNemo === "CL") return "Chile"
    if (countryNemo === "PE") return "Peru"
    if (countryNemo === "UY") return "Uruguay"
  } catch (err) {
    console.log(err)
  } finally {
    return "Chile"
  }
}

const urlHasQueryString = (location: Location) => {
  if (!location) return false
  return location.search.length > 0
}

const didTheUserEnterDirectly = (document: WindowDocument) => {
  if (!document) return false
  return document.referrer.length === 0
}

const directUtms = (country: string) =>
  `${QUERY_STRING_BASE}&country=${country}&utm_source=directo`

const reuseUtms = (location: Location, country: string) => {
  if (!location) return ""

  if (location.search.includes("country")) {
    return location.search
  } else {
    return location.search + "&country=" + country
  }
}

const organicUtms = (document: WindowDocument, country: string) => {
  if (!document) return ""

  const domain = document.referrer.split(".")[1]

  if (DOMAINS.includes(domain)) {
    return `${QUERY_STRING_BASE}&country=${country}&utm_source=${domain}`
  } else {
    return `${QUERY_STRING_BASE}&country=${country}&utm_source=otro_sitio`
  }
}

export const getUtms = (
  location: Location,
  document: WindowDocument,
  country: string
) => {
  if (urlHasQueryString(location)) {
    return reuseUtms(location, country)
  } else {
    if (didTheUserEnterDirectly(document)) {
      return directUtms(country)
    } else {
      return organicUtms(document, country)
    }
  }
}
